import React from 'react'
import { Link } from 'react-router-dom'

const Products = () => {
  return (
    <>
    {/* Product Start */}
  <div className="container-xxl py-6">
    <div className="container py-4">
    <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
        <h1 className="display-5 mb-3">Our Products</h1>
        <p>We follow the best practices for manufacturing and here to international safety, hygiene,and quality standards.</p>
      </div>
      <div className="row g-0 gx-5 align-items-end">
        <div className="col-lg-6">
          <div className=" text-start mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
            <h1 className="display-5 mb-3">Our Products</h1>
            {/* <p>Thoughtfully Packaged to Keep You Revitalised Throughout the Day. Choose Purity, Choose Quality - Choose Ever.</p> */}
          </div>
        </div>
        {/* <div className="col-lg-6 text-start text-lg-end wow slideInRight" data-wow-delay="0.1s">
          <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
            <li className="nav-item me-2">
              <a className="btn btn-outline-primary border-2 active" data-bs-toggle="pill" href="#tab-1">Ever 1L</a>
            </li>
            <li className="nav-item me-2">
              <a className="btn btn-outline-primary border-2" data-bs-toggle="pill" href="#tab-2">Everth 1L</a>
            </li>
            <li className="nav-item me-0">
              <a className="btn btn-outline-primary border-2" data-bs-toggle="pill" href="#tab-3">BIO-D 200ML</a>
            </li>
          </ul>
        </div> */}
      </div>
      <div className="tab-content">
        <div id="tab-1" className="tab-pane fade show p-0 active">
          <div className="row g-4">
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever1.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div className="text-center p-2">
                  {/* <a className="d-block h5 mb-2" href>Ever 2L</a> */}
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 2L</a>

                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
                {/* <div className="d-flex border-top">
                  <small className="w-100 text-center border-end py-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>More Info</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div> */}
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever2.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 1L</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
                
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever3.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 500ML</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
                
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever4.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3" style={{paddingTop:"20px"}}>New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 250ML</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
                
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever5.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 1L</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
               
              </div>
            </div>
            
            
            <div>
            
            </div>
            {/* <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
              <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href>Browse More Products</a>
            </div> */}
          </div>
        </div>

       <div>
        {/* <div id="tab-2" className="tab-pane fade show p-0">
          <div className="row g-4">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-1.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-2.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-3.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-4.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-5.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-6.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-7.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-8.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <a className="btn btn-primary rounded-pill py-3 px-5" href>Browse More Products</a>
            </div>
          </div>
        </div>
        <div id="tab-3" className="tab-pane fade show p-0">
          <div className="row g-4">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-1.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-2.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-3.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-4.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-5.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-6.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-7.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-8.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <a className="btn btn-primary rounded-pill py-3 px-5" href>Browse More Products</a>
            </div>
          </div>
        </div> */}
       </div>

      </div>
    </div>
  </div>
  {/* Product End */}
  {/* Office Visit Start */}
   {/* <div className="container-fluid mt-5 py-6" style={{backgroundColor:"lightblue"}}>
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-md-7 wow fadeIn" data-wow-delay="0.1s">
          <h1 className="display-5  mb-3">Visit Our Office</h1>
          <p className=" mb-0">We follow the best practices for manufacturing and here to international safety, hygiene,and quality standards.</p>
          <p className=" mb-0">Thoughtfully Packaged to Keep You Revitalised Throughout the Day. Choose Purity, Choose Quality - Choose Ever Bharat.</p>

        </div>
        <div className="col-md-5 text-md-end wow fadeIn" data-wow-delay="0.5s">
        <Link className="btn btn-lg btn-primary rounded-pill py-2 px-5" to="/contact">Visit Now</Link>
        </div>
      </div>
    </div>
  </div>  */}
  {/* Office Visit End */}
    </>
  )
}

export default Products