import React from 'react'
import { Link } from 'react-router-dom'

const Navbar = () => {
  return (
    <>
    {/* Navbar Start */}
  <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
    <div className="top-bar row gx-0 align-items-center d-none d-lg-flex" style={{backgroundColor:"green", height:"35px", color:"white"}}>
      <div className="col-lg-6 px-5 text-start " >
        <small><i className="fa fa-map-marker-alt me-2" />Gharaunda, Karnal, Haryana.</small>
        <small className="ms-4"><i className="fa fa-envelope me-2" />everbharat750@gmail.com</small>
      </div>
      <div className="col-lg-6 px-5 text-end">
        <small> Help Line no&nbsp; : &nbsp;</small>
        {/* <small> +91-9991555750 , </small> */}
        <small> 017 48 250251 &nbsp;&nbsp; | </small>

        <Link className="text-body ms-3" to><i className="fab fa-facebook-f text-white" /></Link>
        <Link className="text-body ms-3" to><i className="fab fa-twitter text-white" /></Link>
        <Link className="text-body ms-3" to><i className="fab fa-linkedin-in text-white" /></Link>
        <Link className="text-body ms-3" to><i className="fab fa-instagram text-white" /></Link>
      </div>
    </div>
    <nav className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s"  style={{height:"60px"}} >
      <Link to="" >
        <img src='img/ever-logo1.png' alt='logo' style={{ width:"130px" , height:"40px"}}></img>
        {/* <h1 className="fw-bold text-primary m-0">E<span className="text-secondary">ve</span>r</h1> */}
      </Link>
      <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto p-4 p-lg-0">
          <Link to="/" className="nav-item nav-link active">Home</Link>
          <Link to="/about" className="nav-item nav-link">About Us</Link>
          <Link to="/products" className="nav-item nav-link">Products</Link>
          {/* <div className="nav-item dropdown">
            <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</Link>
            <div className="dropdown-menu m-0">
              <Link to="" className="dropdown-item">Blog Grid</Link>
              <Link to="" className="dropdown-item">Our Features</Link>
              <Link to="" className="dropdown-item">Testimonial</Link>
             
            </div>
          </div> */}
          <Link to="/contact" className="nav-item nav-link">Contact Us</Link>
        </div>
        <div className="d-none d-lg-flex ms-2">
          {/* <Link className="btn-sm-square bg-white rounded-circle ms-3" to>
            <small className="fa fa-search text-body" />
          </Link> */}
          <Link className="btn-sm-square bg-white rounded-circle ms-3" to>
            <small className="fa fa-user text-body" />
          </Link>
          <Link className="btn-sm-square bg-white rounded-circle ms-3" to>
            <small className="fa fa-shopping-bag text-body" />
          </Link>
        </div>
      </div>
    </nav>
  </div>
  {/* Navbar End */}
    </>
  )
}

export default Navbar