import React from 'react'


const Home = () => {
  return (
    <>
<div>
  {/* Navbar Start */}
   
  {/* Navbar End */}
  {/* Carousel Start */}
  <div className="container-fluid p-0 mb-0 wow fadeIn py-5" data-wow-delay="0.1s">
    <div id="header-carousel" className="carousel slide py-5" data-bs-ride="carousel">
       <div className="carousel-inner py-0">

        <video className="d-block w-100" autoPlay loop muted>
            <source src="img/evervideo.mp4" type="video/mp4" />
             Your browser does not support the video tag.
        </video>

       {/*  <div className="carousel-item active">
         <img className="w-100" src="img/carousel-1.jpg" alt="Image" />
           <div className="carousel-caption">
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-lg-7">
                  <h1 className="display-3 mb-5 animated slideInDown">Ever Water Is Good For Health</h1>
                  <a href className="btn btn-primary rounded-pill py-sm-2 px-sm-5">Products</a>
                  <a href className="btn btn-secondary rounded-pill py-sm-2 px-sm-5 ms-3">Services</a>
                </div>
              </div>
            </div>
          </div>
   
        </div>
        <div className="carousel-item">
          <img className="w-100" src="img/carousel-2.jpg" alt="Image" />
          <div className="carousel-caption">
            <div className="container">
              <div className="row justify-content-start">
                <div className="col-lg-7">
                  <h1 className="display-3 mb-5 animated slideInDown">Ever Water Is Always Healthy</h1>
                  <a href className="btn btn-primary rounded-pill py-sm-2 px-sm-5">Products</a>
                  <a href className="btn btn-secondary rounded-pill py-sm-2 px-sm-5 ms-3">Services</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        */}
      </div>
      {/* <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="visually-hidden">Next</span>
      </button> */}
    </div>
  </div>
  {/* Carousel End */}
  {/* About Start */}
  <div className="container-xxl bg-light " >
    <div className="container">
    <div className="section-header text-center mx-auto mb-3 wow fadeInUp " >
        <h1 className="display-5 mb-3">About Us</h1>
        {/* <p>Ever Bharat Gharaunda, Panipat, Haryana, India.</p> */}
      </div>
      <div className="row g-5 align-items-center">
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img position-relative overflow-hidden p-5 pe-0">
            <img className="img-fluid w-100" src="img/about1.png" />
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
        <h1 className="display-5 mb-4">Made at the most advanced plant</h1>
          <p className="mb-3 h5"> International standard machinery and equipment used to produce the water you love <i class="fa fa-heart-o" aria-hidden="true"></i> </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> Dual media filtrations with  5 layers of filtrations </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> Anthracite carbon filtration </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> Soft water with less hardness </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> 7 stage of micro cartridge filtration </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> Hygienic environment all around the plant </p>
          
          {/* <p><i className="fa fa-check text-primary me-3" />Tempor erat elitr rebum at clita</p>
          <p><i className="fa fa-check text-primary me-3" />Aliqu diam amet diam et eos</p>
          <p><i className="fa fa-check text-primary me-3" />Clita duo justo magna dolore erat amet</p>
           */}
          {/* <a className="btn btn-primary rounded-pill py-2 px-5 mt-3" href>Read More</a> */}
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
  {/* Feature Start */}
    {/* <div className="container-fluid bg-light my-3 py-5">
  <div className="container">
    <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
      <h1 className="display-5 mb-3">Our Features</h1>
      <p>Thoughtfully Packaged to Keep You Revitalised Throughout the Day. Choose Purity, Choose Quality - Choose Ever Bharat.</p>
    </div>
    <div className="row g-4">
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <div className="bg-white text-center h-100 p-4 p-xl-5">
          <img className="img-fluid mb-4" src="img/icon-1.png" alt />
          <h4 className="mb-3">Ever - 2L</h4>
          <p className="mb-4">Choose Purity, Choose Quality - Choose Ever Bharat.</p>
          <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href>Read More</a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
        <div className="bg-white text-center h-100 p-4 p-xl-5">
          <img className="img-fluid mb-4" src="img/icon-2.png" alt />
          <h4 className="mb-3">Ever - 1L</h4>
          <p className="mb-4">Choose Purity, Choose Quality - Choose Ever Bharat.</p>
          <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href>Read More</a>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
        <div className="bg-white text-center h-100 p-4 p-xl-5">
          <img className="img-fluid mb-4" src="img/icon-3.png" alt />
          <h4 className="mb-3">Ever - 500ML/250ML</h4>
          <p className="mb-4">Choose Purity, Choose Quality - Choose Ever Bharat.</p>
          <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href>Read More</a>
        </div>
      </div>
    </div>
  </div>
</div> */}
  {/* Feature End */}
  {/* Product Start */}
  <div className="container-xxl py-5">
    <div className="container">
    <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
        <h1 className="display-5 mb-3">Our Products</h1>
        <p>We follow the best practices for manufacturing and here to international safety, hygiene,and quality standards.</p>
      </div>
      <div className="row g-0 gx-5 align-items-end">
        <div className="col-lg-6">
          <div className=" text-start mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
            <h1 className="display-5 mb-3">Our Products</h1>
            {/* <p>Thoughtfully Packaged to Keep You Revitalised Throughout the Day. Choose Purity, Choose Quality - Choose Ever.</p> */}
          </div>
        </div>
        {/* <div className="col-lg-6 text-start text-lg-end wow slideInRight" data-wow-delay="0.1s">
          <ul className="nav nav-pills d-inline-flex justify-content-end mb-5">
            <li className="nav-item me-2">
              <a className="btn btn-outline-primary border-2 active" data-bs-toggle="pill" href="#tab-1">Ever 1L</a>
            </li>
            <li className="nav-item me-2">
              <a className="btn btn-outline-primary border-2" data-bs-toggle="pill" href="#tab-2">Everth 1L</a>
            </li>
            <li className="nav-item me-0">
              <a className="btn btn-outline-primary border-2" data-bs-toggle="pill" href="#tab-3">BIO-D 200ML</a>
            </li>
          </ul>
        </div> */}
      </div>
      <div className="tab-content">
        <div id="tab-1" className="tab-pane fade show p-0 active">
          <div className="row g-4">
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever1.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className=" btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 2L</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
                
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever2.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 1L</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
               
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever3.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 500ML</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
               
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.7s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever4.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3" style={{paddingTop:"20px"}}>New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 250ML</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
                
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product/ever5.png" alt />
                  {/* <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div> */}
                </div>
                <div className="text-center p-2">
                  <a className="btn btn-outline-primary border-2 py-1 px-4 rounded-pill" href>Ever 1L</a>
                  {/* <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                 */}
                </div>
                
              </div>
            </div>
            <div>
            
            </div>
            {/* <div className="col-12 text-center wow fadeInUp" data-wow-delay="0.1s">
              <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href>Browse More Products</a>
            </div> */}
          </div>
        </div>

       <div>
        {/* <div id="tab-2" className="tab-pane fade show p-0">
          <div className="row g-4">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-1.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-2.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-3.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-4.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-5.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-6.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-7.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-8.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <a className="btn btn-primary rounded-pill py-3 px-5" href>Browse More Products</a>
            </div>
          </div>
        </div>
        <div id="tab-3" className="tab-pane fade show p-0">
          <div className="row g-4">
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-1.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-2.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-3.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-4.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-5.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-6.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-7.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-6">
              <div className="product-item">
                <div className="position-relative bg-light overflow-hidden">
                  <img className="img-fluid w-100" src="img/product-8.jpg" alt />
                  <div className="bg-secondary rounded text-white position-absolute start-0 top-0 m-4 py-1 px-3">New</div>
                </div>
                <div className="text-center p-4">
                  <a className="d-block h5 mb-2" href>Fresh Tomato</a>
                  <span className="text-primary me-1">$19.00</span>
                  <span className="text-body text-decoration-line-through">$29.00</span>
                </div>
                <div className="d-flex border-top">
                  <small className="w-50 text-center border-end py-2">
                    <a className="text-body" href><i className="fa fa-eye text-primary me-2" />View detail</a>
                  </small>
                  <small className="w-50 text-center py-2">
                    <a className="text-body" href><i className="fa fa-shopping-bag text-primary me-2" />Add to cart</a>
                  </small>
                </div>
              </div>
            </div>
            <div className="col-12 text-center">
              <a className="btn btn-primary rounded-pill py-3 px-5" href>Browse More Products</a>
            </div>
          </div>
        </div> */}
       </div>

      </div>
    </div>
  </div>
  {/* Product End */}
  {/* Firm Visit Start */}
  {/* <div className="container-fluid mt-5 py-6" style={{backgroundColor:"lightblue"}}>
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-md-7 wow fadeIn" data-wow-delay="0.1s">
          <h1 className="display-5  mb-3">Visit Our Office</h1>
          <p className=" mb-0">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos. Clita erat ipsum et lorem et sit, sed stet lorem sit clita duo justo magna dolore erat amet. Diam dolor diam ipsum sit. Aliqu diam amet diam et eos.</p>
        </div>
        <div className="col-md-5 text-md-end wow fadeIn" data-wow-delay="0.5s">
          <a className="btn btn-lg btn-primary rounded-pill py-2 px-5" href>Visit Now</a>
        </div>
      </div>
    </div>
  </div> */}
  {/* Firm Visit End */}
  {/* Testimonial Start */}
  {/* <div className="container-fluid bg-light py-6 mb-5">
    <div className="container">
      <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
        <h1 className="display-5 mb-3">Customer Review</h1>
        <p>Tempor ut dolore lorem kasd vero ipsum sit eirmod sit. Ipsum diam justo sed rebum vero dolor duo.</p>
      </div>
      <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
        <div className="testimonial-item position-relative bg-white p-5 mt-4">
          <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5" />
          <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
          <div className="d-flex align-items-center">
            <img className="flex-shrink-0 rounded-circle" src="img/testimonial-1.jpg" alt />
            <div className="ms-3">
              <h5 className="mb-1">Client Name</h5>
              <span>Profession</span>
            </div>
          </div>
        </div>
        <div className="testimonial-item position-relative bg-white p-5 mt-4">
          <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5" />
          <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
          <div className="d-flex align-items-center">
            <img className="flex-shrink-0 rounded-circle" src="img/testimonial-2.jpg" alt />
            <div className="ms-3">
              <h5 className="mb-1">Client Name</h5>
              <span>Profession</span>
            </div>
          </div>
        </div>
        <div className="testimonial-item position-relative bg-white p-5 mt-4">
          <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5" />
          <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
          <div className="d-flex align-items-center">
            <img className="flex-shrink-0 rounded-circle" src="img/testimonial-3.jpg" alt />
            <div className="ms-3">
              <h5 className="mb-1">Client Name</h5>
              <span>Profession</span>
            </div>
          </div>
        </div>
        <div className="testimonial-item position-relative bg-white p-5 mt-4">
          <i className="fa fa-quote-left fa-3x text-primary position-absolute top-0 start-0 mt-n4 ms-5" />
          <p className="mb-4">Tempor erat elitr rebum at clita. Diam dolor diam ipsum sit diam amet diam et eos. Clita erat ipsum et lorem et sit.</p>
          <div className="d-flex align-items-center">
            <img className="flex-shrink-0 rounded-circle" src="img/testimonial-4.jpg" alt />
            <div className="ms-3">
              <h5 className="mb-1">Client Name</h5>
              <span>Profession</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}
  {/* Testimonial End */}
  {/* Blog Start */}
  <div className="container-xxl py-0">
    <div className="container">
      <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
        <h1 className="display-5 mb-3">Latest Blog</h1>
        {/* <p>Thoughtfully Packaged to Keep You Revitalised Throughout the Day. Choose Purity, Choose Quality - Choose Ever Bharat.</p> */}
      </div>
      <div className="row g-4">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <img className="img-fluid" src="img/blog/ever-blog1.png" alt />
          <div className="bg-light p-4">
            {/* <a className="d-block h5 lh-base mb-4" href>How to cultivate organic fruits and vegetables in own firm</a> */}
            <div className="text-muted border-top pt-4">
              <small className="me-3"><i className="fa fa-user text-primary me-2" />Admin</small>
              <small className="me-3"><i className="fa fa-calendar text-primary me-2" />  2024</small>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <img className="img-fluid" src="img/blog/ever-blog3.png" alt />
          <div className="bg-light p-4">
            {/* <a className="d-block h5 lh-base mb-4" href>How to cultivate organic fruits and vegetables in own firm</a> */}
            <div className="text-muted border-top pt-4">
              <small className="me-3"><i className="fa fa-user text-primary me-2" />Admin</small>
              <small className="me-3"><i className="fa fa-calendar text-primary me-2" />  2024</small>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <img className="img-fluid" src="img/blog/ever-blog2.png" alt />
          <div className="bg-light p-4">
            {/* <a className="d-block h5 lh-base mb-4" href>How to cultivate organic fruits and vegetables in own firm</a> */}
            <div className="text-muted border-top pt-4">
              <small className="me-3"><i className="fa fa-user text-primary me-2" /> Admin </small>
              <small className="me-3"><i className="fa fa-calendar text-primary me-2" />  2024 </small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Blog End */}
  {/* Footer Start */}
  
  {/* Footer End */}
  {/* Back to Top */}
  
</div>
    </>
  )
}

export default Home