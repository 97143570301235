import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
  return (
    <>
     {/* About Start */}
  <div className="container-xxl bg-light py-6">
   <div className="container py-3">
    <div className="section-header text-center mx-auto mb-3 wow fadeInUp " >
        <h1 className="display-5 mb-3">About Us</h1>
        {/* <p>Ever Bharat Gharaunda, Panipat, Haryana, India.</p> */}
      </div>
      <div className="row g-5 align-items-center">
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
          <div className="about-img position-relative overflow-hidden p-5 pe-0">
            <img className="img-fluid w-100" src="img/about1.png" />
          </div>
        </div>
        <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
          <h1 className="display-4 mb-4">Made at the most advanced plant</h1>
          <p className="mb-3 h5"> International standard machinery and equipment used to produce the water you love <i class="fa fa-heart-o" aria-hidden="true"></i> </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> Dual media filtrations with  5 layers of filtrations </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> Anthracite carbon filtration </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> Soft water with less hardness </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> 7 stage of micro cartridge filtration </p>
          <p className="mb-2 h5"> <i class="fa fa-check" aria-hidden="true"></i> Hygienic environment all around the plant </p>
          
          {/* <p><i className="fa fa-check text-primary me-3" />Tempor erat elitr rebum at clita</p>
          <p><i className="fa fa-check text-primary me-3" />Aliqu diam amet diam et eos</p>
          <p><i className="fa fa-check text-primary me-3" />Clita duo justo magna dolore erat amet</p>
           */}
          {/* <a className="btn btn-primary rounded-pill py-2 px-5 mt-3" href>Read More</a> */}
        </div>
      </div>
    </div>
  </div>
  {/* About End */}
  {/* Office Visit Start */}
  {/* <div className="container-fluid mt-5 py-6" style={{backgroundColor:"lightblue"}}>
    <div className="container">
      <div className="row g-5 align-items-center">
        <div className="col-md-7 wow fadeIn" data-wow-delay="0.1s">
          <h1 className="display-5  mb-3">Visit Our Office</h1>
          <p className=" mb-0">We follow the best practices for manufacturing and here to international safety, hygiene,and quality standards.</p>
          <p className=" mb-0">Thoughtfully Packaged to Keep You Revitalised Throughout the Day. Choose Purity, Choose Quality - Choose Ever Bharat.</p>

        </div>
        <div className="col-md-5 text-md-end wow fadeIn" data-wow-delay="0.5s">
          <Link className="btn btn-lg btn-primary rounded-pill py-2 px-5" to="/contact">Visit Now</Link>
        </div>
      </div>
    </div>
  </div>  */}
  {/* Office Visit End */}
  {/* Feature Start */}
    <div className="container-fluid bg-light my-0 py-0" >
  <div className="container">
    <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
      <h1 className="display-5 mb-3">Our Features</h1>
      <p>Thoughtfully Packaged to Keep You Revitalised Throughout the Day. Choose Purity, Choose Quality - Choose Ever.</p>
    </div>
    <div className="row g-4">
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
        <div className="bg-white text-center h-100 p-4 p-xl-5">
          <img className="img-fluid mb-4" src="img/icon-1.png" alt />
          {/* <h4 className="mb-3">Ever - 2L</h4> */}
          <p className="mb-4">Choose Purity, Choose Quality - Choose Ever Bharat.</p>
          {/* <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href>Read More</a> */}
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
        <div className="bg-white text-center h-100 p-4 p-xl-5">
          <img className="img-fluid mb-4" src="img/icon-2.png" alt />
          {/* <h4 className="mb-3">Ever - 1L</h4> */}
          <p className="mb-4">Choose Purity, Choose Quality - Choose Ever Bharat.</p>
          {/* <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href>Read More</a> */}
        </div>
      </div>
      <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
        <div className="bg-white text-center h-100 p-4 p-xl-5">
          <img className="img-fluid mb-4" src="img/icon-3.png" alt />
          {/* <h4 className="mb-3">Ever - 200ML</h4> */}
          <p className="mb-4">Choose Purity, Choose Quality - Choose Ever Bharat.</p>
          {/* <a className="btn btn-outline-primary border-2 py-2 px-4 rounded-pill" href>Read More</a> */}
        </div>
      </div>
    </div>
  </div>
</div>
  {/* Feature End */}
    </>
  )
}

export default About