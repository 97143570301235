import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <>
    {/* Footer Start */}
  <div className="container-fluid bg-dark footer mt-4 pt-4 wow fadeIn" data-wow-delay="0.1s">
    <div className="container py-4">
      <div className="row g-5">
        <div className="col-lg-3 col-md-6">
        <Link to="/">
         <img src='img/ever-logo1.png' alt='logo' style={{ width:"120px" ,height:"40px", paddingBottom:"5px"}}></img>
        </Link>
        {/* <h1 className="fw-bold text-primary m-0">E<span className="text-secondary">ve</span>r</h1> */}
     
          {/* <h1 className="fw-bold text-primary mb-4">F<span className="text-secondary">oo</span>dy</h1> */}
          <p>Thoughtfully Packaged to Keep You Revitalised Throughout the Day. Choose Purity, Choose Quality - Choose Ever Bharat.</p>
          <div className="d-flex pt-2">
            <a className="btn btn-square btn-outline-light rounded-circle me-1" href><i className="fab fa-twitter" /></a>
            <a className="btn btn-square btn-outline-light rounded-circle me-1" href><i className="fab fa-facebook-f" /></a>
            <a className="btn btn-square btn-outline-light rounded-circle me-1" href><i className="fab fa-youtube" /></a>
            <a className="btn btn-square btn-outline-light rounded-circle me-0" href><i className="fab fa-linkedin-in" /></a>
          </div>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Address</h4>
          <p><i className="fa fa-map-marker-alt me-3" />Gharaunda, Karnal, Haryana.</p>
          <p><i className="fa fa-phone-alt me-3" />+91-9991555750</p>
          <p><i className="fa fa-phone-alt me-3" />+91-9050874321</p>
          <p><i className="fa fa-phone-alt me-3" />01748 250251</p>
          <p><i className="fa fa-envelope me-2" />everbharat750@gmail.com</p>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Quick Links</h4>
          <Link className="btn btn-link" to="">Home</Link>
          <Link className="btn btn-link" to="">About Us</Link>
          <Link className="btn btn-link" to="">Contact Us</Link>
          <Link className="btn btn-link" to="">Our Products</Link>
        </div>
        <div className="col-lg-3 col-md-6">
          <h4 className="text-light mb-4">Newsletter</h4>
          <p>Choose Purity, Choose Quality - Choose Ever Bharat.</p>
          <div className="position-relative mx-auto" style={{maxWidth: 400}}>
            <input className="form-control bg-transparent w-100 py-3 ps-4 pe-5" type="text" placeholder="Your email" />
            <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">SignUp</button>
          </div>
        </div>
      </div>
    </div>
    <div className="container-fluid copyright">
      <div className="container">
        <div className="row">
          <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
            © <a href="#">www.ever.everbharat.co</a>, All Right Reserved.
          </div>
          {/* <div className="col-md-6 text-center text-md-end">
            Designed By <a href="https://htmlcodex.com">HTML Codex</a>
            <br />Distributed By: <a href="https://themewagon.com" target="_blank">ThemeWagon</a>
          </div> */}
        </div>
      </div>
    </div>
  </div>
  {/* Footer End */}
  {/* Copyright End */}
  <a href="https://api.whatsapp.com/send?phone=+919050874321&text=Hello..." class="float" target="_blank">
   <i class="fa fa-whatsapp my-float"></i>
   </a>
  {/* Back to Top */}
  <a href="#" className="btn btn-lg text-white btn-lg-square rounded-circle back-to-top" style={{backgroundColor:"green", bottom: "72px",  boxShadow: "2px 3px 3px black"}}><i className="bi bi-arrow-up" /></a>
	
    </>
  )
}

export default Footer