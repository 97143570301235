import Footer from "./Components/Footer";
import Home from "./Components/Home";
import { Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Contact from "./Components/Contact";
import About from "./Components/About";
import Products from "./Components/Products";


function App() {
  return (
    <>
    
    <Navbar />
    <Routes>
      <Route path="/" element={<Home />}></Route>
      <Route path="/contact" element={<Contact />}></Route>
      <Route path="/about" element={<About />}></Route>
      <Route path="/products" element={<Products />}></Route>

    </Routes>
    <Footer />
    </>
  );
}

export default App;
