import React from 'react'

const Contact = () => {
  return (
    <>
     <div>
     {/* Page Header Start */}
{/* <div className="container-fluid page-header wow fadeIn" data-wow-delay="0.1s">
  <div className="container">
    <h1 className="display-3 mb-3 animated slideInDown">Contact Us</h1>
    <nav aria-label="breadcrumb animated slideInDown">
      <ol className="breadcrumb mb-0">
        <li className="breadcrumb-item"><a className="text-body" href="#">Home</a></li>
        <li className="breadcrumb-item"><a className="text-body" href="#">Pages</a></li>
        <li className="breadcrumb-item text-dark active" aria-current="page">Contact Us</li>
      </ol>
    </nav>
  </div>
</div> */}
{/* Page Header End */}
  {/* Contact Start */}
  <div className="container-xxl py-6">
    <div className="container">
      <div className="section-header text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{maxWidth: 500}}>
        <h1 className="display-5 mb-3">Contact Us</h1>
        <p>We follow the best practices for manufacturing and here to international safety, hygiene,and quality standards.</p>
      </div>
      <div className="row g-5 justify-content-center">
        <div className="col-lg-5 col-md-12 wow fadeInUp" data-wow-delay="0.1s">
          <div className="  d-flex flex-column justify-content-center h-100 p-5" style={{backgroundColor:"#dcedfc"}}>
            <h5 className="">Call Us</h5>
            <p className="mb-2"><i className="fa fa-phone-alt me-3" />+91 9991555750 </p>
            <p className="mb-2"><i className="fa fa-phone-alt me-3" />+91 9050874321</p>
            <p className="mb-3"><i className="fa fa-phone-alt me-3" />01748 250251</p>
            <h5 className="">Email Us</h5>
            <p className="mb-3"><i className="fa fa-envelope me-3" />everbharat750@gmail.com</p>
            <h5 className="">Office Address</h5>
            <p className="mb-3"><i className="fa fa-map-marker-alt me-3" />Ever Bharat, Gharaunda, Karnal, Haryana, India.</p>
            <h5 className="">Follow Us</h5>
            <div className="d-flex pt-0">
              <a className="btn btn-square  rounded-circle me-1" href><i className="fab fa-twitter" /></a>
              <a className="btn btn-square  rounded-circle me-1" href><i className="fab fa-facebook-f" /></a>
              <a className="btn btn-square  rounded-circle me-1" href><i className="fab fa-youtube" /></a>
              <a className="btn btn-square  rounded-circle me-0" href><i className="fab fa-linkedin-in" /></a>
            </div>
          </div>
        </div>
        <div className="col-lg-7 col-md-12 wow fadeInUp" data-wow-delay="0.5s">
          <p className="mb-4"> </p>
          <form>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="form-floating">
                  <input type="text" className="form-control" id="name" placeholder="Your Name" />
                  <label htmlFor="name">Your Name</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <input type="email" className="form-control" id="email" placeholder="Your Email" />
                  <label htmlFor="email">Your Email</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <input type="text" className="form-control" id="subject" placeholder="Subject" />
                  <label htmlFor="subject">Subject</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <textarea className="form-control" placeholder="Leave a message here" id="message" style={{height: 150}} defaultValue={""} />
                  <label htmlFor="message">Message</label>
                </div>
              </div>
              <div className="col-12">
                <button className="btn btn-primary rounded-pill py-3 px-5" type="submit">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  {/* Contact End */}
  {/* Google Map Start */}
  {/* <div className="container-xxl px-0 wow fadeIn" data-wow-delay="0.1s" style={{marginBottom: '-6px'}}>
    <iframe className="w-100" style={{height: 450}} src=" " frameBorder={0} allowFullScreen aria-hidden="false" tabIndex={0} />
  </div> */}
  {/* Google Map End */}
</div>

    </>
  )
}

export default Contact